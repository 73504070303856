<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <AccountContent class="grid-sm-full grid-md-8 grid-lg-8"
                          :params="{transparent: true}">
            <Row class="account-content__agencies-header m-b-40"
                 wrap
                 justify="between">
              <h5 class="account-content__agencies-title">Мой гид</h5>
            </Row>
            <Row>
              <p class="account-content__subtitle">На нашем портале вы можете разместить свои услуги
                в качестве гида, если вы хотите поделиться интересной информацией с гостями города и
                заработать, то лучшее решение — разместить свою анкету в нашей базе гидов.</p>
            </Row>
            <Loader v-if="!ready"
                    :params="{style: {height: '355px', margin: '30px 0 0'}}"/>
            <Row v-else
                 justify="between"
                 class="-m-h-15"
                 wrap>
              <NewGuideCard v-if="!$my.guide"
                            v-on:click.native="$openPopupAddGuide($my.guide)"/>
              <GuideCardEditor :key="item.id"
                               :params="item"
                               class="grid-md-4 grid-lg-4"
                               v-for="item in [...$my.guide]"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountBecomeGuideView',
  data() {
    return {
      navigation: [{
        href: '/',
        title: 'Главная',
      }, {
        href: '/account',
        title: 'Личный кабинет',
      }],
      ready: false,
    };
  },
  created() {
    this.$http.get('/api/guides?user=current')
    // eslint-disable-next-line no-return-assign
      .then((resolve) => {
        this.ready = true;
        this.$store.commit('SET_MY_GUIDE', resolve.data.data[0]);
      });
  },
};
</script>
